import React from "react";
import styles from './Styles.module.scss';
import classNames from 'classnames/bind';
import {getDeepestCategory, storyToPath} from "@/lib/utils/storyUtils";
import Link from "next/link";
import getImageUrls from "@/lib/utils/getImageUrls";
import Button from "@/components/button/Button";
import CtaArrow from "@/components/svg/CtaArrow";
import CategoryLink from "@/components/link/categoryLink/CategoryLink";
import LockIcon from "@/components/svg/LockIcon";
import LazyImage from "@/components/lazyImage/LazyImage";

const cx = classNames.bind(styles);

export default function StoryCard({
                                    className,
                                    story,
                                    home,
                                    hero,
                                    printHero,
                                  }) {

  const {sponsored, excerpt} = story;
  const category = getDeepestCategory(story);

  const thumbnail = getImageUrls(story.thumbnail, {width: 600, height: 600});
  const heroThumb = getImageUrls(story.thumbnail, {width: 900, height: 532});

  return (
      <div className={cx(styles.container,
          {home, hero, sponsored, printHero},
          className)}
      >
        <div className={styles.innerContainer}>
          <div className={styles.content}>
            <Link href={storyToPath(story)}>
              <a>
                {(home && hero)
                    ? (
                        <img className={cx({heroThumb})} src={heroThumb.image.url} alt={heroThumb.alt}/>
                    ) : (
                        <LazyImage className={styles.thumbnail} image={thumbnail.image} alt={heroThumb.alt}/>
                    )
                }
              </a>
            </Link>
            <div className={styles.text}>
              <div className={styles.sponsored}>Sponsored</div>
              <CategoryLink className={styles.category} category={category}/>
              {printHero && (<div className={styles.lockContainer}>
                <LockIcon className={styles.lockIcon}/>
                <p className={styles.exclusiveContentLock}>exclusive to premium members</p>
              </div>)}
              <Link href={storyToPath(story)}>
                <a>
                  <h2>{story.headline}</h2>
                  <p>{excerpt}</p>
                  <div className={styles.readMore}>
                    <div className={styles.readMoreText}>Read</div>
                    <CtaArrow className={styles.arrowIcon}/>
                  </div>
                </a>
              </Link>
              {printHero && (
                  <Link href="/subscribe">
                    <a>
                      <Button solid black className={styles.button}>Subscribe to read</Button>
                    </a>
                  </Link>
              )}
            </div>
          </div>
        </div>
      </div>
  )
}
