import React from "react";

export default function Ad({className, ad}) {
  if(!ad) {
    return null;
  }
  const {href, url, alt} = ad;
  return (
      <a className={className} href={href} target={'_blank'}>
        <img src={url} alt={alt}/>
      </a>
  )
}
