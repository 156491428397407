import React, {useEffect, useRef, useState} from 'react';
import useOnScreen from '@/lib/utils/useOnScreen';
import getImageUrls from "@/lib/utils/getImageUrls";

export default function LazyImage({className, image, alt}) {
  const lazyImageRef = useRef();
  const isLazyImageOnScreen = useOnScreen(lazyImageRef);
  const [hasBeenOnScreen, setHasBeenOnScreen] = useState(isLazyImageOnScreen);

  useEffect(() => {
    if (isLazyImageOnScreen && !hasBeenOnScreen) {
      setHasBeenOnScreen(true);
    }
  }, [isLazyImageOnScreen, hasBeenOnScreen])

  if (!image) {
    return null;
  }

  const imageToRender = hasBeenOnScreen
      ? image
      : getImageUrls(image, {width: 1, height: 1});

  return (
      <img ref={lazyImageRef} src={imageToRender.url} className={className} alt={alt}/>
  )
}
