import {useRef} from "react";
import seedRandom from "seedrandom";

export default function useRandom() {
  const loadDate = useRef(new Date());
  let i = 0;
  return () => {
    i++;
    return seedRandom(i + loadDate.current.toISOString())();
  }
}
