import React from "react";
import styles from './CategoryLink.module.scss';
import classNames from 'classnames/bind';
import Link from "next/link";
import {categoryToPath} from "@/lib/utils/categoryUtils";

const cx = classNames.bind(styles);

export default function CategoryLink({className, category}) {
  const title = category?.title || "[Category]";
  const href = categoryToPath(category);
  return (
      <div className={cx(styles.categoryLink, className)}>
        {category
            ? (
                <Link href={href}>
                  <a>{title}</a>
                </Link>
            )
            : title}
      </div>
  );
}
