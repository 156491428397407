import React from "react";
import styles from './Styles.module.scss';
import classNames from 'classnames';
import Ad from "@/components/ad/Ad";

export default function XPlatformAd({className, ad, homepageTakeover}) {
  const {mobile, tablet} = ad;
  const desktop = homepageTakeover?.center || ad?.desktop;
  return (
      <div className={classNames(styles.container, className)}>
        {mobile && <div className={styles.mobile}>
          <Ad ad={mobile}/>
          <div className={styles.footer}>Advertisement</div>
        </div>}
        {tablet && <div className={styles.tablet}>
          <Ad ad={tablet}/>
          <div className={styles.footer}>Advertisement</div>
        </div>}
        {desktop && <div className={styles.desktop}>
          <Ad ad={desktop}/>
          <div className={styles.footer}>Advertisement</div>
        </div>}
      </div>
  )
}
