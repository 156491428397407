import {categoryToPath} from "@/lib/utils/categoryUtils";

export function storyToPath(story) {

  const deepestCategory = getDeepestCategory(story);

  // Recursively concatenate the category path and add the story slug
  return deepestCategory
      ? `${categoryToPath(deepestCategory)}/${story.slug}`
      : story.slug;
}

export function getStorySitePath({story, site}) {
  const categories = (story.allCategories ?? []).filter(c => c.site == site);
  const deepestCategory = getDeepestCategory({...story, categories});
  return `${categoryToPath(deepestCategory)}/${story.slug}`;
}

export function getDeepestCategory(story) {
  const categories = story.categories ?? []
  // Recursively drill down to the deepest category (first if more than one)
  const depth = (c) => (c.parent ? depth(c.parent) : 0) + 1;
  const maxDepth = Math.max(categories.map(depth));
  return categories.find(c => depth(c) === maxDepth);
}
